import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import { injectIntl } from "react-intl";
import styles from "../styles";
import { withStyles, IconButton, Dialog, DialogActions, DialogContent } from "@material-ui/core";
import ChartBase from "./ChartBase.js";

//Lines below have to be last
import createPlotlyComponent from "react-plotly.js/factory";
import Plotly from "plotly.js-basic-dist-min";
const Plot = createPlotlyComponent(Plotly);

class ChartEFSignature extends ChartBase {
  constructor(props) {
    super(props);
    this.state = {
      data: this.getData(),
      layout: this.getLayout(),
      modalDiagram: false,
      configPlot: { 
        responsive: true,
        modeBarButtonsToAdd: [
          {
            // name: this.props.intl.formatMessage({id: "chart.zoom"}),
            name: "Magnify",
            icon: {
              "name": this.props.intl.formatMessage({id: "chart.zoom"}),
              "svg": '<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" '
                      +'height="24" viewBox="0 0 24 24" width="24">'
                      +'<g><rect fill="none" height="24" width="24"/></g><g><g><g>'
                      +'<path d="M15,3l2.3,2.3l-2.89,2.87l1.42,1.42L18.7,6.7L21,9V3H15z '
                      +'M3,9l2.3-2.3l2.87,2.89l1.42-1.42L6.7,5.3L9,3H3V9z '
                      +'M9,21 l-2.3-2.3l2.89-2.87l-1.42-1.42L5.3,17.3L3,15v6H9z '
                      +'M21,15l-2.3,2.3l-2.87-2.89l-1.42,1.42l2.89,2.87L15,21h6V15z"/>'
                      +'</g></g></g></svg>'
            },
            click: this.openDialog
          }
        ],
      },
      revision: 0,
      selectedPlots: {}
    };
  }

  // LABELQUANTITIES = {
  //   "energy": {false: "chart.label.energy", true: 'chart.label.power'},
  //   "flow": {false: "chart.label.volume", true: "chart.label.flow"}
  // }
  // UNITS = {
  //   "energy": {false: "MWh", true: 'kW'},
  //   "flow": {false: "m³", true: "m³/h"}
  // }

  LABELQUANTITIES = {
    "energy": "chart.label.energy",
    "flow": "chart.label.volume"
  }
  UNITS = {
    "energy": "MWh",
    "flow": "m³"
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedPlots !== this.props.selectedPlots) {
      this.updateSelection(this.state.data, this.props.selectedPlots);      
    }
    if (prevProps.labelPlots !== this.props.labelPlots) {
      this.updateLabels(this.state.data, this.props.labelPlots);      
    }
    if (prevProps.showBadData !== this.props.showBadData) {
      this.convertDataToXY();
      this.updateSelection(this.state.data, this.props.selectedPlots);      
    }
    if (prevProps.separateCalculated !== this.props.separateCalculated
        || prevProps.useAltUnit !== this.props.useAltUnit
        || prevProps.data !== this.props.data 
        || prevProps.alarms !== this.props.alarms) {
      this.convertDataToXY();
    } else if (prevProps.markedLength !== this.props.markedLength
        || (typeof this.props.markedStart === "object"
        && prevProps.markedStart !== this.props.markedStart) ) {
      if (!this.props.waitingForData) {
        this.updateMarkedData();
      }
    }
    if (this.props.chartHoverLabel !== prevProps.chartHoverLabel
        || prevProps.useAltUnit !== this.props.useAltUnit) {
      this.setState({layout: this.getLayout()})
    }
  }
  closeDialog = () => {
    this.setState({ modalDiagram: false });
  };

  openDialog = () => {
    this.setState({ modalDiagram: true });
  };

  compareDates = (a, b) => {
    return new Date(a) - new Date(b);
  };

  updateSelection = (data, selectedPlots) => {
    data = this.removePlotdata(data, "selection");
    data = this.updateIndicatorPlotData(data, selectedPlots, "selection", false, "temperature");
    this.setState({ data, revision: this.state.revision+1 });  
  }

  updateLabels = (data, labelPlots) => {
    data = this.updateIndicatorPlotData(data, labelPlots, "label", true, "temperature");
    this.setState({ data, revision: this.state.revision+1 });  
  }

  removePlotdata = (data, searchTag) => {
    let newdata = data.filter((item) => {return item.searchTag !== searchTag});
    return newdata;
  }

  updateMarkedData_old = () => {
    const LABELQUANTITY = this.LABELQUANTITIES[this.props.title];
    const UNIT = this.UNITS[this.props.title];
    let formattedName = this.props.intl.formatMessage({ id: "chart.marked" })
    let indexOfExisting = this.state.data.findIndex((x) => x.name === formattedName)
    let groupMarked = this.state.data[indexOfExisting];
    if (this.props.markedLength > 0) {
      let newGroup = false;
      if (typeof groupMarked === "undefined") {
        let color = this.props.colorPattern["marked"];
        groupMarked = {
          type: "scatter",
          mode: "markers",
          name: this.props.intl.formatMessage({ id: "chart.marked" }),
          marker: {
            color: color, symbol: this.props.chartSymbol,
            size: this.props.chartSymbolSize,
            line: {color: this.props.colorPattern["marked_2"], width: 1}
          },
          hovertemplate: this.props.intl.formatMessage({ id: LABELQUANTITY })
            + ': %{y} ' + UNIT + '<br>'
            + this.props.intl.formatMessage({id: "chart.outdoor_temperature"})
            + ': %{x} °C<br>%{text|%Y-%m-%d}'
        };
        newGroup = true;
      }
      let markedPoints = [];
      let x = [];
      let y = [];
      let text = [];
      this.props.data.reg.forEach(element => {
        element.regressions.sort((a, b) => this.compareDates(a.timestamp, b.timestamp))
            .forEach(meterValue => {
          if (
            new Date(meterValue.timestamp).getTime() >= this.props.markedStart.getTime() &&
            new Date(meterValue.timestamp).getTime() <= this.props.markedEnd.getTime()
          ) {
            markedPoints.push({
              x: this.roundValue(meterValue.temperature, 3),
              y: this.roundValue(meterValue.consumption, 3),
              text: new Date(meterValue.timestamp)
            })
          }
        });
      });
      markedPoints.sort((p1,p2) => (p1.text < p2.text ? -1 : 1))
      for (const mp of markedPoints) {
        x.push(mp.x);
        y.push(mp.y);
        text.push(mp.text);
      }

      //Change units if needed
      var scf = 1;
      if (this.props.useAltUnit) {
        if (this.props.title === "energy") {
          scf = 1000.0/24.0;
        } else if (this.props.title === "flow") {
          scf = 1/24.0;
        }
      }
      if (scf !== 1) {
        for (let i = 0; i < y.length; ++i) {
          y[i] *= scf;
        }
      }

      groupMarked["x"] = x;
      groupMarked["y"] = y;
      groupMarked["text"] = text;
      let data = this.state.data;
      if (newGroup) {
        data.push(groupMarked);
      }
      this.setState({ data, revision: this.state.revision+1 });
    } else {  //else if (this.props.markedLength == 0)
      if (typeof groupMarked !== "undefined") {
        //Remove marked group
        let data = this.state.data;
        data.splice(indexOfExisting,1);
        this.setState({ data, revision: this.state.revision+1 });
      }
    }
  };


  updateMarkedData = () => {
    const LABELQUANTITY = this.LABELQUANTITIES[this.props.title];
    const UNIT = this.UNITS[this.props.title];
    let formattedName = this.props.intl.formatMessage({ id: "chart.marked" })
    let indexOfExisting = this.state.data.findIndex((x) => x.name === formattedName)
    let groupMarked = this.state.data[indexOfExisting];

    if (this.props.markedLength > 0) {
      let newGroup = false;
      if (typeof groupMarked === "undefined") {
        let color = this.props.colorPattern["marked"];
        groupMarked = {
          type: "scatter",
          mode: "markers",
          name: this.props.intl.formatMessage({ id: "chart.marked" }),
          marker: {
            color: color, symbol: this.props.chartSymbol,
            size: this.props.chartSymbolSize,
            line: {color: this.props.colorPattern["marked_2"], width: 1}
          },
          hovertemplate: this.props.intl.formatMessage({ id: LABELQUANTITY })
            + ': %{y} ' + UNIT + '<br>'
            + this.props.intl.formatMessage({id: "chart.outdoor_temperature"})
            + ': %{x} °C<br>%{text|%Y-%m-%d}'
        };
        newGroup = true;
      }
      let markedPoints = [];
      let x = [];
      let y = [];
      let text = [];
      if(this.props.data != undefined){
        Array.from(this.props.data).forEach((meterValue) => {
          if (
            new Date(meterValue.timestamp).getTime() >= this.props.markedStart.getTime() &&
            new Date(meterValue.timestamp).getTime() <= this.props.markedEnd.getTime()
          ) {
            markedPoints.push({
              x: this.roundValue(meterValue.temperature, 3),
              y: this.roundValue(meterValue.consumption, 3),
              text: new Date(meterValue.timestamp)
            })
          }
        });
      }

      markedPoints.sort((p1, p2) => (p1.text < p2.text ? -1 : 1));
      for (const mp of markedPoints) {
        x.push(mp.x);
        y.push(mp.y);
        text.push(mp.text);
      }

      groupMarked["x"] = x;
      groupMarked["y"] = y;
      groupMarked["text"] = text;
      let data = this.state.data;
      if (newGroup) {
        data.push(groupMarked);
      }
      this.setState({ data, revision: this.state.revision+1 });
    } else {  //else if (this.props.markedLength == 0)
      if (typeof groupMarked !== "undefined" && indexOfExisting >= 0) {
        //Remove marked group
        let data = this.state.data;
        data.splice(indexOfExisting,1);
        this.setState({ data, revision: this.state.revision+1 });
      }
    }
  };


  /**
   *
   * @param {int} value The number to be rounded
   * @param {int} numberOfDecimals How many decimals to round the number to
   */
  roundValue = (value, numberOfDecimals) => {
    try {
      return +value.toFixed(numberOfDecimals);
    } catch (error) {
      return null;
    }
  };

  convertAlarmsToXy = (data) => {
    const LABELQUANTITY = this.LABELQUANTITIES[this.props.title];
    const UNIT = this.UNITS[this.props.title];
    if (typeof data === "undefined" || data.lenth === 0) return;
    let color = this.props.colorPattern["alarms"];
    let groupAlarms = {
      type: "scatter",
      mode: "markers",
      name: this.props.intl.formatMessage({ id: "chart.alarms" }),
      connectgaps: false,
      // visible: "legendonly", //TODO Perhaps hide by default?
      marker: { color: color, symbol: this.props.chartSymbol, size: this.props.chartSymbolSize },
      hovertemplate: this.props.intl.formatMessage({ id: LABELQUANTITY })
            + ': %{y} ' + UNIT + '<br>'
            + this.props.intl.formatMessage({id: "chart.outdoor_temperature"})
            + ': %{x} °C<br>%{text|%Y-%m-%d}'
    };
    let x = [];
    let y = [];
    let text = [];
    data.forEach(meterValue => {
      if (meterValue.calculated.deviation_level === "critical") {
        x.push(this.roundValue(meterValue.calculated.weather_data.outdoor_temperature, 3));
        y.push(this.roundValue(meterValue.consumption.value, 3));
        text.push(new Date(meterValue.consumption.from));
      }
    });
    groupAlarms["x"] = x;
    groupAlarms["y"] = y;
    groupAlarms["text"] = text;
    return groupAlarms;
  };



  convertDataToXY = () => {
    let data = [{yaxis: "y2"}];
    const LABELQUANTITY = this.LABELQUANTITIES[this.props.title];
    const UNIT = this.UNITS[this.props.title];
    

    if (typeof this.props.data === "undefined") return;

    const separateCalculated = this.props.separateCalculated;
    let nrGroups = 0;
    let nrRefGroups = 0;
    for (let i = 0; i < this.props.data.length; i++) {
      const element = this.props.data[i];
      if (element.Group+1 > nrGroups) {
        nrGroups = element.Group+1;
      }

      if (element.ref_Group+1 > nrRefGroups) {
        nrRefGroups = element.ref_Group+1;
      }
    }
    for (let i = 0; i < nrGroups; ++i) {
      // Measurements
      let color = this.props.colorPattern["group" + (i + 1)];
      let group = {
        type: "scatter",
        mode: "markers",
        name: this.props.intl.formatMessage({ id: "chart.group" }) + " " + (i + 1),
        marker: { color: color, symbol: this.props.chartSymbol, size: this.props.chartSymbolSize },
        zeroline: false,
        hovertemplate: this.props.intl.formatMessage({ id: LABELQUANTITY })
            + ': %{y} ' + UNIT + '<br>'
            + this.props.intl.formatMessage({id: "chart.outdoor_temperature"})
            + ': %{x} °C<br>%{text|%Y-%m-%d}'
      };
      let group2;
      if (separateCalculated) {
        group2 = Object.assign({}, group);
        group["name"] = this.props.intl.formatMessage({ id: "chart.group" }) + " " + (i + 1) + 
          " (" + (this.props.intl.formatMessage({ id: "chart.group.measured" })) + ")"
        group2["name"] = this.props.intl.formatMessage({ id: "chart.group" }) + " " + (i + 1) + 
          " (" + (this.props.intl.formatMessage({ id: "chart.group.calculated" })) + ")"
        group2["marker"] = {
          color: color,
          symbol: this.props.chartSymbol+"-open",
          size: this.props.chartSymbolSize
        }
      }
      group["x"] = [];
      group["y"] = [];
      group["text"] = [];
      group["timestamp"] = [];
      group["selectable"] = true
      group["group_id"] = i;
      data.push(group);
      if (separateCalculated) {
        group2["x"] = [];
        group2["y"] = [];
        group2["text"] = [];
        group2["timestamp"] = [];
        group2["selectable"] = true
        group2["group_id"] = i;
        data.push(group2);
      }
    }
    // Regression lines
    for (let i = 0; i < nrGroups; ++i) {
      let color3 = this.props.colorPattern["reg" + (i + 1)];
      let group3 = {
        type: "scatter",
        mode: "lines",
        // mode: "lines+markers",
        name: "Reg " + (i + 1),
        line: { color: color3 },
        marker: { color: color3 },
        hovertemplate: this.props.intl.formatMessage({ id: LABELQUANTITY })
            + ': %{y} ' + UNIT + '<br>'
            + this.props.intl.formatMessage({id: "chart.outdoor_temperature"})
            + ': %{x} °C'
      };
      group3["x"] = [];
      group3["y"] = [];
      group3["text"] = [];
      data.push(group3);
    }
    // Reference lines
    for (let i = 0; i < nrRefGroups; ++i) {
      let color = this.props.colorPattern["ref" + (i + 1)];
      let group = {
        type: "scatter",
        // mode: "lines+markers",
        mode: "lines",
        name: "Ref " + (i + 1),
        line: { color: color, dash: "dot" },
        marker: { color: color },
        hovertemplate: this.props.intl.formatMessage({ id: LABELQUANTITY })
            + ': %{y} ' + UNIT + '<br>'
            + this.props.intl.formatMessage({id: "chart.outdoor_temperature"})
            + ': %{x} °C'
      };
      group["x"] = [];
      group["y"] = [];
      group["text"] = [];
      data.push(group);
    }

    //Groups and regression lines data
    Array.from(this.props.data)
    .sort((a, b) => {
      return a.temperature - b.temperature;
    })
    .forEach((element) => {
      let groupNr = parseInt(element.Group);
      let groupIndex = (groupNr * (separateCalculated ? 2 : 1) + 1);
      let regGroupIndex = (groupNr + nrGroups * (separateCalculated ? 2 : 1) + 1);
      let group = data[groupIndex];
      let regGroup = data[regGroupIndex];
      if (separateCalculated && element.reading_status === "calculated") {
        group = data[groupIndex+1];
      }
      group['x'].push(this.roundValue(element.temperature, 3));
      group['y'].push(this.roundValue(element.consumption, 3));
      group['text'].push(new Date(element.timestamp));
      group['timestamp'].push(element.timestamp);
      regGroup['x'].push(this.roundValue(element.temperature, 3));
      regGroup['y'].push(this.roundValue(element.model, 3));
      regGroup['text'].push(new Date(element.timestamp));
    });


    // Reference lines data
    if (nrRefGroups > 0) {
      this.props.data
      .sort((a, b) => {return a.temperature - b.temperature})
      .forEach((element) => {
        let refGroupNr = parseInt(element.ref_Group);
        let refGroupIndex = (1 + refGroupNr + nrGroups * (separateCalculated ? 3 : 2));
        let refGroup = data[refGroupIndex];
        refGroup['x'].push(this.roundValue(element.temperature, 3));
        refGroup['y'].push(this.roundValue(element.model_ref[element.ref_Group], 3));
        refGroup['text'].push(this.roundValue(element.temperature, 3));
      });
    }

    // Marked
    this.updateMarkedData();

    //Alarms
    let groupAlarms = this.convertAlarmsToXy(this.props.alarms);
    data.push(groupAlarms);

    this.setState({ data }), () => {console.error("Alert 2");};    
  };

  convertDataToXY_old = () => {
    let data = [{yaxis:"y2"}];
    // let data = [];
    const LABELQUANTITY = this.LABELQUANTITIES[this.props.title];
    const UNIT = this.UNITS[this.props.title];

    if (typeof this.props.data.reg === "undefined") return;
    
    this.props.data.reg.forEach((element, index) => {
      let color = this.props.colorPattern["group" + (index + 1)];
      let group = {
        type: "scatter",
        mode: "markers",
        name: this.props.intl.formatMessage({ id: "chart.group" }) + " " + (index + 1),
        marker: { color: color, symbol: this.props.chartSymbol, size: this.props.chartSymbolSize },
        zeroline: false,
        hovertemplate: this.props.intl.formatMessage({ id: LABELQUANTITY })
            + ': %{y} ' + UNIT + '<br>'
            + this.props.intl.formatMessage({id: "chart.outdoor_temperature"})
            + ': %{x} °C<br>%{text|%Y-%m-%d}'
      };
      if (!this.props.separateCalculated) {
        let x = [];
        let y = [];
        let text = [];
        let timestamp = [];

        element.regressions
          .sort((a, b) => this.compareDates(a.timestamp, b.timestamp))
          .forEach(meterValue => {
            x.push(this.roundValue(meterValue.temperature, 3));
            y.push(this.roundValue(meterValue.consumption, 3));
            text.push(new Date(meterValue.timestamp));
            timestamp.push(meterValue.timestamp);
          });
        group["x"] = x;
        group["y"] = y;
        group["text"] = text;
        group["timestamp"] = timestamp;
        group["group_id"] = element.group_id;
        data.push(group);
      } else {
        let group2 = Object.assign({}, group);
        group["name"] = this.props.intl.formatMessage({ id: "chart.group" }) + " " + (index + 1) + 
          " (" + (this.props.intl.formatMessage({ id: "chart.group.measured" })) + ")"
        group2["name"] = this.props.intl.formatMessage({ id: "chart.group" }) + " " + (index + 1) + 
          " (" + (this.props.intl.formatMessage({ id: "chart.group.calculated" })) + ")"
        group2["marker"] = {
          color: color,
          symbol: this.props.chartSymbol+"-open",
          size: this.props.chartSymbolSize
        }

        let x = [], x2 = [];
        let y = [], y2 = [];
        let text = [], text2 = [];
        let timestamp = [];
  
        element.regressions.sort((a, b) => this.compareDates(a.timestamp, b.timestamp))
            .forEach(meterValue => {
          if (meterValue.reading_status === "measured") {
            x.push(this.roundValue(meterValue.temperature, 3));
            y.push(this.roundValue(meterValue.consumption, 3));
            text.push(new Date(meterValue.timestamp));
            timestamp.push(meterValue.timestamp);
          } else if (meterValue.reading_status === "calculated") {
            x2.push(this.roundValue(meterValue.temperature, 3));
            y2.push(this.roundValue(meterValue.consumption, 3));
            text2.push(new Date(meterValue.timestamp));
          }
        });
        group["x"] = x;
        group["y"] = y;
        group["text"] = text;
        group["timestamp"] = timestamp;
        group["group_id"] = element.group_id;
        data.push(group);

        group2["x"] = x2;
        group2["y"] = y2;
        group2["text"] = text2;
        data.push(group2);
      }
    });

    this.props.data.reg.forEach((element, index) => {
      let color = this.props.colorPattern["reg" + (index + 1)];
      let group = {
        type: "scatter",
        mode: "lines+markers",
        name: "Reg " + (index + 1),
        line: { color: color },
        marker: { color: color },
        hovertemplate: this.props.intl.formatMessage({ id: LABELQUANTITY })
            + ': %{y} ' + UNIT + '<br>'
            + this.props.intl.formatMessage({id: "chart.outdoor_temperature"})
            + ': %{x} °C'
      };
      let x = [];
      let y = [];
      let text = [];
      element.fixed.forEach(meterValue => {
        x.push(this.roundValue(meterValue.temperature, 3));
        y.push(this.roundValue(meterValue.calculated_value, 3));
        text.push(this.roundValue(meterValue.temperature, 3));
      });
      group["x"] = x;
      group["y"] = y;
      group["text"] = text;
      data.push(group);
    });

    this.props.data.ref.forEach((element, index) => {
      let color = this.props.colorPattern["ref" + (index + 1)];
      let group = {
        type: "scatter",
        mode: "lines+markers",
        name: "Ref " + (index + 1),
        line: { color: color, dash: "dot" },
        marker: { color: color },
        hovertemplate: this.props.intl.formatMessage({ id: LABELQUANTITY })
            + ': %{y} ' + UNIT + '<br>'
            + this.props.intl.formatMessage({id: "chart.outdoor_temperature"})
            + ': %{x} °C'
      };
      let x = [];
      let y = [];
      let text = [];
      element.fixed.forEach(meterValue => {
        x.push(this.roundValue(meterValue.temperature, 3));
        y.push(this.roundValue(meterValue.calculated_value, 3));
        text.push(this.roundValue(meterValue.temperature, 3));
      });
      group["x"] = x;
      group["y"] = y;
      group["text"] = text;
      data.push(group);
    });

    if (this.props.markedLength > 0) {
      let color = this.props.colorPattern["marked"];
      let groupMarked = {
        type: "scatter",
        mode: "markers",
        name: this.props.intl.formatMessage({ id: "chart.marked" }),
        marker: {
          color: color, symbol: this.props.chartSymbol,
          size: this.props.chartSymbolSize,
          line: {color: this.props.colorPattern["marked_2"], width: 1}
        },
        hovertemplate: this.props.intl.formatMessage({ id: LABELQUANTITY })
            + ': %{y} ' + UNIT + '<br>'
            + this.props.intl.formatMessage({id: "chart.outdoor_temperature"})
            + ': %{x} °C<br>%{text|%Y-%m-%d}'
      };
      let x = [];
      let y = [];
      let text = [];
      this.props.data.reg.forEach((element) => {
        element.regressions.forEach((meterValue) => {
          if (
            new Date(meterValue.timestamp).getTime() >= this.props.markedStart.getTime() &&
            new Date(meterValue.timestamp).getTime() <= this.props.markedEnd.getTime()
          ) {
            x.push(this.roundValue(meterValue.temperature, 3));
            y.push(this.roundValue(meterValue.consumption, 3));
            text.push(meterValue.timestamp);
          }
        });
      });
      groupMarked["x"] = x;
      groupMarked["y"] = y;
      groupMarked["text"] = text;
      data.push(groupMarked);
    }
    //Alarms
    let groupAlarms = this.convertAlarmsToXy(this.props.alarms);
    data.push(groupAlarms);

    //Change units if needed
    var scf = 1;
    if (this.props.useAltUnit) {
      if (this.props.title === "energy") {
        scf = 1000.0/24.0;
      } else if (this.props.title === "flow") {
        scf = 1/24.0;
      }
    }
    if (scf !== 1) {
      for (let i = 0; i < data.length; ++i) {
        if (data[i]["y"]) {
          for (let j = 0; j < data[i]["y"].length; ++j) {
            data[i]["y"][j] *= scf;
          }
        }
      }
    }

    this.setState({ data });
  };

  getData = () => {
    return [
      {
        x: [1e-9, 1e-8],
        y: [1e-9, 1e-8],
        mode: "markers",
        marker: { color: this.props.colorPattern["bgcolor"] },
        text: ['',''],
      },
    ];
  };

  getLayout = () => {
    const xLabel = this.props.intl.formatMessage({
      id: "chart.temperature_outdoor_daily_average"
    })
    var yLabelL, yLabelR;
    if (this.props.title === "energy") {
      yLabelL = (this.props.useAltUnit) ? "chart.energy_daily_average" : "chart.energy_per_day";
      yLabelR = "chart.energy_daily_average";
    } else if (this.props.title === "flow") {
      yLabelL = (this.props.useAltUnit) ? "chart.flow_daily_average" : "chart.flow_per_day";
      yLabelR = "chart.flow_daily_average";
    }
    yLabelL = this.props.intl.formatMessage({ id: yLabelL });
    yLabelR = this.props.intl.formatMessage({ id: yLabelR });

    return {
      title: {
        text: this.props.intl.formatMessage({ id: this.props.title }),
        y: 1,
        x: 0.5,
        xanchor: "center",
        yanchor: "top",
        pad: {t: 8}
      },
      margin: { l: 50, r: 50, t: 10, b: 10, pad: 10 },
      autosize: true,
      legend: { orientation: "h" },
      xaxis: { title: xLabel, titlefont: { size: 9 }, zeroline: this.props.zerolines },
      yaxis: { title: yLabelL, titlefont: { size: 9 }, zeroline: this.props.zerolines },
      yaxis2: {
        side: "right",
        overlaying: "y1",
        titlefont: { size: 9 },
        title: yLabelR,
        showgrid: false,
        matches: "y",
        tickformat: "scale_"+
          (this.props.title === "energy" ? ""+1000/24+"_.0f" : ""+1/24+"_.2f"),
        zeroline: this.props.zerolines
      },
      plot_bgcolor: this.props.colorPattern.bgcolor,
      paper_bgcolor: this.props.colorPattern.papercolor,
      hovermode: (this.props.chartHoverLabel === false) ? false : 'closest',
      hoverdistance: 1
    };
  };

  get_selected_points = function (data, eventData) {
    if (typeof eventData == 'undefined') {
      return;
    }
    var curves_data = {};
    for(var i=0; i < eventData.points.length; i++){
      let pointNumber = eventData.points[i].pointNumber;
      let curveNumber = eventData.points[i].curveNumber;
      if ("selectable" in data[curveNumber]) {
        if (!(curveNumber in curves_data)) {
        curves_data[curveNumber] = {
          "group_id": data[curveNumber]["group_id"],
            "name": data[curveNumber]["name"],
            "timestamp": [],
            "temperature": [],
            "consumption": [],  
          };
        };
        curves_data[curveNumber]["timestamp"].push(data[curveNumber]["timestamp"][pointNumber]);
        curves_data[curveNumber]["temperature"].push(data[curveNumber]["x"][pointNumber]);
        curves_data[curveNumber]["consumption"].push(data[curveNumber]["y"][pointNumber]);
      };
    }
    return curves_data;
  };

  append_selection(accumulatedSels, newSelections) {
    for (var key in newSelections) {
      let selection = newSelections[key]
      if (!(key in accumulatedSels)) {
        accumulatedSels[key] = {
          "timestamp": [],
          "temperature": [],
          "consumption": [],
          "group_id": selection["group_id"],
          "name": selection["name"] + " -"+ this.props.intl.formatMessage({ id: "chart.marked" })
        }
      }

      let accSel = accumulatedSels[key];
      for (let id = 0; id < selection.timestamp.length; ++id) {
        if (!accSel["timestamp"].includes(selection.timestamp[id])) {
          accSel["timestamp"].push(selection.timestamp[id]);
          accSel["temperature"].push(selection.temperature[id]);
          accSel["consumption"].push(selection.consumption[id]);
        }
      }      
    }
  return accumulatedSels;
  }

  handleSelected = function (eventData) {
    if (typeof eventData === "undefined") {
      let emptySelection = {};
      this.props.notifyNewSelection(emptySelection);
      return;
    }
    let newSelection = this.get_selected_points(this.state.data, eventData);
    let accumulatedSelection = {...this.props.selectedPlots};
    this.append_selection(accumulatedSelection, newSelection);
    this.props.notifyNewSelection(accumulatedSelection);
  }

  render() {
    const { classes } = this.props;
    const title = this.props.title + "_signature";
    return (
      <React.Fragment>
        <Dialog
          open={this.state.modalDiagram}
          onClose={this.closeDialog}
          aria-labelledby="modal-diagram-dialog-title"
          aria-describedby="modal-diagram-description"
          fullWidth
          maxWidth={false}
        >
          <div>
            <DialogActions>
              <IconButton onClick={this.closeDialog}>
                <CloseIcon />
              </IconButton>
            </DialogActions>
            <DialogContent className={classes.p90Height}>
              <Plot
                divId={title}
                data={this.state.data}
                layout={this.state.layout}
                config={this.state.configPlot}
                useResizeHandler={true}
                style={{ width: "100%", height: "792px" }}
              />
            </DialogContent>
          </div>
        </Dialog>
        <Plot
          debug={true}
          divId={title}
          data={this.state.data}
          layout={this.state.layout}
          config={this.state.configPlot}
          revision={this.state.revision}
          useResizeHandler={true}
          style={{ width: "100%", height: this.props.chartHeight }}
          onHover={p => this.props.pOnHover(title, p, true)}
          onUnhover={p => this.props.pOnHover(title, p, false)}
          onRelayout={r => this.props.pOnRelayout(title, r)}
          onSelected={data => this.handleSelected(data)}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(injectIntl(ChartEFSignature));
