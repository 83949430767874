import axios from "axios";
import AppConxt from "../AppContext";
import { useContext } from "react";

function useMeterReadings() {
  const context = useContext(AppConxt);

  async function getStatus(n_days) {
    return context.keycloak
      .updateToken(5)
      .then(function () {
        const headers = { Authorization: "Bearer " + context.keycloak.token };
        return axios
          .get(context.config.meterReadingsAPI + "/status", {
            headers,
            params: { n_days },
          })
          .then((response) => {
            return response.data;
          });
      })
      .catch(function () {
        context.keycloak.login();
      });
  }

  return { getStatus };
}

export default useMeterReadings;
