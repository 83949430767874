import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { ExportToCsv } from "export-to-csv";
import Cookies from "js-cookie";

import {
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Grid,
  Tooltip,
  IconButton,
  Paper,
  Typography,
  TextField,
} from "@material-ui/core";

import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import FilterListIcon from "@material-ui/icons/FilterList";

import AlarmsTableFilter from "./AlarmsTableFilter";
import { statuses } from "./MeteringPointInformation";

import styles from "../styles";

const properties = ["energy", "flow"];

function desc(a, b, orderBy) {
  if (b[orderBy] === "-") {
    return -2;
  }
  if (a[orderBy] === "-") {
    return 2;
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((element, index) => [element, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((element) => element[0]);
}

function getSorting(order, orderBy) {
  return order === "desc" ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

class AlarmsTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      order: Cookies.get("k2_alarms_order") || "desc",
      orderBy: Cookies.get("k2_alarms_order_by") || "number_of_alarms",
      selectedProperties: Cookies.get("k2_alarms_filter_selected_properties")
        ? JSON.parse(Cookies.get("k2_alarms_filter_selected_properties"))
        : properties,
      selectedDeviation: Cookies.get("k2_alarms_filter_selected_deviation") || "deviation_all",
      selectedStatuses: Cookies.get("k2_alarms_filter_selected_statuses")
        ? JSON.parse(Cookies.get("k2_alarms_filter_selected_statuses"))
        : JSON.parse(JSON.stringify(statuses.concat([""]))),
      page: 0,
      selectedMinimumNumberOfAlarms: parseInt(Cookies.get("k2_alarms_filter_selected_minimum_number_of_alarms") || "0"),
      selectedMaximumNumberOfAlarms: parseInt(Cookies.get("k2_alarms_filter_selected_maximum_number_of_alarms") || "9999"),
      rowsPerPage: parseInt(Cookies.get("k2_alarms_rows_per_page") || "10"),
      search: "",
      tags: this.props.tags,
      filterTags: Cookies.get("k2_alarms_filter_tags") ? JSON.parse(Cookies.get("k2_alarms_filter_tags")) : [],
      redirect: false,
      id: 0,
      checked: Cookies.get("k2_alarms_filter_toggle") ? JSON.parse(Cookies.get("k2_alarms_filter_toggle")) : false,
      selectedTags: Cookies.get("k2_alarms_selected_tags") ? JSON.parse(Cookies.get("k2_alarms_selected_tags")) : [],
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.alarms.length !== prevProps.alarms.length) {
      var page = parseInt(Cookies.get("k2_alarms_current_page") || "0");
      if (page * this.state.rowsPerPage < this.props.alarms.length) {
        this.setState({page: page});
      }
    }
  }

  handleFilterOpen = () => {
    // Save the filtered tags when dialog is opened and restore them if user cancels the dialog.
    this.previousState = Object.assign({}, this.state);
    this.setState({ filterDialogOpen: true });
  };

  // Handle cancel button
  handleFilterClose = () => {
    this.setState(this.previousState);
    this.setState({ filterDialogOpen: false });
  };

  handleChangeRowsPerPage = (event) => {
    const { rowsPerPage, page } = this.state;
    const newRowsPerPage = event.target.value;
    const newPage = Math.floor((page * rowsPerPage) / newRowsPerPage);
    Cookies.set("k2_alarms_current_page", ""+newPage, {
      expires: new Date(Date.now() + 1000000000000), sameSite: "lax",
    });
    Cookies.set("k2_alarms_rows_per_page", ""+newRowsPerPage, {
      expires: new Date(Date.now() + 1000000000000), sameSite: "lax",
    });
    this.setState({ rowsPerPage: newRowsPerPage, page: newPage });
  };

  handleChangePage = (event, page) => {
    Cookies.set("k2_alarms_current_page", ""+page, {
      expires: new Date(Date.now() + 1000000000000), sameSite: "lax",
    });
    this.setState({ page });
  };

  handleRowClick = (id) => {
    this.setState({ redirect: true, id: id });
  };

  // Handle OK button
  handleUpdateFilter = (filter) => {
    Cookies.set("k2_alarms_selected_tags", JSON.stringify(this.state.selectedTags), {
      expires: new Date(Date.now() + 1000000000000),
      sameSite: "lax",
    });
    Cookies.set("k2_alarms_filter_tags", JSON.stringify(this.state.filterTags), {
      expires: new Date(Date.now() + 1000000000000),
      sameSite: "lax",
    });
    Cookies.set("k2_alarms_filter_selected_properties", JSON.stringify(filter.selectedProperties), {
      expires: new Date(Date.now() + 1000000000000), sameSite: "lax",
    });
    Cookies.set("k2_alarms_filter_selected_deviation", filter.selectedDeviation, {
      expires: new Date(Date.now() + 1000000000000), sameSite: "lax",
    });
    Cookies.set("k2_alarms_filter_selected_statuses", JSON.stringify(filter.selectedStatuses), {
      expires: new Date(Date.now() + 1000000000000), sameSite: "lax",
    });
    Cookies.set("k2_alarms_filter_selected_minimum_number_of_alarms", filter.selectedMinimumNumberOfAlarms, {
      expires: new Date(Date.now() + 1000000000000), sameSite: "lax",
    });
    Cookies.set("k2_alarms_filter_selected_maximum_number_of_alarms", filter.selectedMaximumNumberOfAlarms, {
      expires: new Date(Date.now() + 1000000000000), sameSite: "lax",
    });
    this.setState({
      selectedProperties: filter.selectedProperties,
      selectedDeviation: filter.selectedDeviation,
      selectedStatuses: filter.selectedStatuses,
      selectedMinimumNumberOfAlarms: filter.selectedMinimumNumberOfAlarms,
      selectedMaximumNumberOfAlarms: filter.selectedMaximumNumberOfAlarms,
      filterDialogOpen: false,
      page: 0,
    });
  };

  handleRequestSort = (property) => (event) => {
    const orderBy = property;
    let order = "desc";
    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    Cookies.set("k2_alarms_order", order, { expires: new Date(Date.now() + 1000000000000), sameSite: "lax" });
    Cookies.set("k2_alarms_order_by", orderBy, { expires: new Date(Date.now() + 1000000000000), sameSite: "lax" });
    this.setState({ order, orderBy });
  };

  handleSearch = (event) => {
    this.setState({ search: event.target.value });
  };

  changeFilterTags = (event) => {
    this.setState({
      filterTags: event,
    });
  };

  /**
   * check if this post should visible or not based on the filter of tags
   *
   * @param {*} data
   */
  checkTagFilter(data) {
    if (this.state.filterTags.length === 0) return true;

    let tags = [];
    data.tags.forEach((tag) => {
      tag.val.forEach((val) => {
        tags.push(tag.key + ":" + val);
      });
    });
    
    if (this.state.checked){
      for(const filter of this.state.filterTags){
        let hasTag = false;
        for (const tag of tags){
          if(tag === filter.key_value){
            if (filter.type_of_filter === "include") {
              return true;
            }
            else{
              hasTag = true;
              break
            }
          }   
        }
        if (hasTag === false && filter.type_of_filter === "exclude") {
          return true;
        }
      }
    }
    else{
      // Walk through the rulesets
      for (const filter of this.state.filterTags) {
        let notInIncluded = false;
        let hasBeenIncluded = false;
        // Check each Tag in alarm row
        for (const tag of tags) {
          // We have it, and it's excluded
          if (tag === filter.key_value && filter.type_of_filter === "exclude") {
            return false;
          }

          // We have it, and it's included
          if (tag === filter.key_value && filter.type_of_filter === "include") {
            hasBeenIncluded = true;
            notInIncluded = false;
          }

          // We don't have it and it's included
          if (tag !== filter.key_value && filter.type_of_filter === "include" && hasBeenIncluded === false) {
            notInIncluded = true;
          }
        }
        // It should be in included, but was not
        if (notInIncluded === true && filter.type_of_filter === "include") {
          return false;
        }

        // There is no tag, but there is a include in the ruleset
        if (tags.length === 0 && filter.type_of_filter === "include") {
          return false;
        }
      }
      // It has survived all rulesets
      return true;
    }
  }

  filterAlarms(alarms) {
    let filteredAlarms = [];
    for (var alarm of alarms) {
      if (
        this.state.selectedProperties.includes(alarm.meter_reading_property) &&
        alarm[this.state.selectedDeviation] &&
        this.state.selectedStatuses.includes(alarm.metering_point_status) &&
        (alarm.metering_point_id.toUpperCase().includes(this.state.search.toUpperCase()) ||
          alarm.street_address.toUpperCase().includes(this.state.search.toUpperCase())) &&
        alarm[this.state.selectedDeviation].number_of_alarms >= this.state.selectedMinimumNumberOfAlarms &&
        alarm[this.state.selectedDeviation].number_of_alarms <= this.state.selectedMaximumNumberOfAlarms
      ) {
        var filteredAlarm = {
          metering_point_id: alarm.metering_point_id,
          street_address: alarm.street_address,
          meter_reading_property: alarm.meter_reading_property,
          number_of_alarms: alarm[this.state.selectedDeviation].number_of_alarms != null ? alarm[this.state.selectedDeviation].number_of_alarms : '-',
          accumulated: alarm[this.state.selectedDeviation].accumulated_abs != null ? +alarm[this.state.selectedDeviation].accumulated_abs.toFixed(3) : '-',
          accumulated_opposite: alarm[this.state.selectedDeviation].accumulated_opposite != null ? +alarm[this.state.selectedDeviation].accumulated_opposite.toFixed(3) : '-',
          normalized_accumulated: alarm[this.state.selectedDeviation].normalized_accumulated != null ? +alarm[this.state.selectedDeviation].normalized_accumulated.toFixed(3) : '-',
          delta_t: alarm.delta_t != null ? +alarm.delta_t.toFixed(3) : '-',
          overflow: alarm.overflow != null ? +alarm.overflow.toFixed(3) : '-',
          return_temperature: alarm.return_temperature != null  ? +alarm.return_temperature.toFixed(3) : '-',
          flow_temperature: alarm.flow_temperature != null? +alarm.flow_temperature.toFixed(3) : '-',
          metering_point_status: alarm.metering_point_status,
          newest_note: (alarm.newest_note) ? alarm.newest_note.replace('T',' ').substr(0,19) : '-'
        };
        if (this.checkTagFilter(alarm)) {
          filteredAlarms.push(filteredAlarm);
        }
      }
    }
    return filteredAlarms;
  }

  exportToCSV(filteredAlarms) {
    const csvOptions = {
      filename: "Alarms",
      fieldSeparator: ";",
      quoteStrings: '"',
      decimalseparator: ",",
      showLabels: true,
      showTitle: false,
      useBom: true,
      useKeysAsHeaders: true,
    };

    const csvExporter = new ExportToCsv(csvOptions);

    if (filteredAlarms != undefined && filteredAlarms.length > 0) {
      csvExporter.generateCsv(stableSort(filteredAlarms, getSorting(this.state.order, this.state.orderBy)));
    }
  }

  render() {
    if (this.state.redirect === true) {
      return (
        <Redirect
          push
          to={{
            pathname: "/meteringpoint/" + this.state.id,
            state: { alarmsPeriod: this.props.period },
          }}
        />
      );
    }
    const { classes, intl } = this.props;

    const columns = [
      {
        id: "metering_point_id",
        align: "left",
        label: intl.formatMessage({ id: "metering_points.metering_point" }),
      },
      {
        id: "street_address",
        align: "left",
        label: intl.formatMessage({ id: "metering_points.address" }),
      },
      {
        id: "meter_reading_property",
        align: "left",
        label: intl.formatMessage({ id: "alarm.meter_reading_property" }),
      },
      {
        id: "number_of_alarms",
        align: "right",
        label: intl.formatMessage({ id: "alarm.number_of_alarms" }),
      },
      {
        id: "accumulated",
        align: "right",
        label: intl.formatMessage({ id: "alarm.accumulated_deviation" }),
      },
      {
        id: "accumulated_opposite",
        align: "right",
        label: intl.formatMessage({ id: "alarm.accumulated_opposite" }),
      },
      {
        id: "normalized_accumulated",
        align: "right",
        label: intl.formatMessage({
          id: "alarm.normalized_accumulated_deviation",
        }),
      },
      {
        id: "delta_t",
        align: "left",
        label: intl.formatMessage({ id: "alarm.delta_t", }),
      },
      {
        id: "overflow",
        align: "left",
        label: intl.formatMessage({ id: "alarm.overflow", }),
      },
      {
        id: "return_temperature",
        align: "left",
        label: intl.formatMessage({ id: "alarm.return_temperature" }),
      },
      {
        id: "flow_temperature",
        align: "left",
        label: intl.formatMessage({ id: "alarm.flow_temperature" }),
      },
      {
        id: "metering_point_status",
        align: "left",
        label: intl.formatMessage({ id: "alarm.metering_point_status" }),
      },
      {
        id: "newest_note",
        align: "left",
        label: intl.formatMessage({ id: "alarm.newest_note" }),
      },
    ];
    const filteredAlarms = this.filterAlarms(this.props.alarms);
    const { order, orderBy, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredAlarms.length - page * rowsPerPage);

    const handleAndOrChange = (checked) => {
      this.setState({ checked });
      Cookies.set("k2_alarms_filter_toggle", JSON.stringify(checked), {
        expires: new Date(Date.now() + 1000000000000),
        sameSite: "lax",
      });
    };

    const handleSelectedTagsChange = (selectedTags) => {
      const removedTags = this.state.selectedTags.filter((x) => !selectedTags.includes(x));
      if (removedTags.length > 0) {
        const newFilterTags = this.state.filterTags.filter((x) => x.key_value.indexOf(removedTags[0]) !== 0);
        this.setState({ filterTags: newFilterTags });
      }
      this.setState({ selectedTags });
    };

    return (
      <Paper>
        <Grid container alignItems="center">
          <React.Fragment>
            <Grid item xs={9} className={classes.AlignLeft}>
              <TextField
                label={this.props.intl.formatMessage({
                  id: "alarm.filter_metering_point",
                })}
                value={this.state.search}
                onChange={this.handleSearch}
                className={classes.MarginLeft}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={3} className={classes.AlignRight}>
              {this.props.alarms.length > 0 && filteredAlarms.length > 0 ? (
                <Tooltip title={intl.formatMessage({ id: "alarm.download_csv" })}>
                  <IconButton onClick={() => this.exportToCSV(filteredAlarms)}>
                    <CloudDownloadIcon />
                  </IconButton>
                </Tooltip>
              ) : null}
              <Tooltip title={intl.formatMessage({ id: "alarm.filter_table" })}>
                <IconButton onClick={this.handleFilterOpen}>
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </React.Fragment>
        </Grid>
        {this.state.filterDialogOpen ? (
          <AlarmsTableFilter
            open={this.state.filterDialogOpen}
            handleClose={this.handleFilterClose}
            properties={properties}
            statuses={statuses}
            selectedProperties={this.state.selectedProperties}
            selectedDeviation={this.state.selectedDeviation}
            selectedStatuses={this.state.selectedStatuses}
            selectedMinimumNumberOfAlarms={this.state.selectedMinimumNumberOfAlarms}
            selectedMaximumNumberOfAlarms={this.state.selectedMaximumNumberOfAlarms}
            handleUpdate={this.handleUpdateFilter}
            tags={this.props.tags}
            handleFilterTags={this.changeFilterTags}
            filterTags={this.state.filterTags}
            selectedTags={this.state.selectedTags}
            handleSelectedTagsChange={(tags) => handleSelectedTagsChange(tags)}
            checked={this.state.checked}
            handleAndOrChange={handleAndOrChange}
          />
        ) : (
          <div />
        )}
        <Table size="small">
          <TableHead>
            <TableRow>
              {columns.map((column) => {
                let deviationTooltipText = null;
                if (column.id === "accumulated") {
                  deviationTooltipText = intl.formatMessage({ id: "alarm.sort.accumulated_deviation" });
                } else if (column.id === "accumulated_opposite") {
                  deviationTooltipText = intl.formatMessage({ id: "alarm.sort.accumulated_opposite" });
                } else if (column.id === "normalized_accumulated") {
                  deviationTooltipText = intl.formatMessage({ id: "alarm.sort.normalized_accumulated_deviation" });
                } else {
                  deviationTooltipText = intl.formatMessage({ id: "alarm.sort" });
                }
                return (
                  <TableCell key={column.id} align={column.align}>
                    <Tooltip title={deviationTooltipText} enterDelay={300}>
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={order}
                        onClick={this.handleRequestSort(column.id)}
                      >
                        {column.label}
                      </TableSortLabel>
                    </Tooltip>
                  </TableCell>
                );
              }, this)}
            </TableRow>
          </TableHead>

          {!this.props.loading ? (
            <TableBody>
              {stableSort(filteredAlarms, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      key={row.metering_point_id + row.meter_reading_property}
                      onClick={(event) => this.handleRowClick(row.metering_point_id)}
                      className={classes.PointerCursor}
                    >
                      <TableCell>{row.metering_point_id}</TableCell>
                      <TableCell>{row.street_address}</TableCell>
                      <TableCell>
                        <FormattedMessage id={"alarm.property_" + row.meter_reading_property} />
                      </TableCell>
                      <TableCell align="right">{row.number_of_alarms}</TableCell>
                      <TableCell align="right">{row.accumulated}</TableCell>
                      <TableCell align="right">{row.accumulated_opposite}</TableCell>
                      <TableCell align="right">{row.normalized_accumulated}</TableCell>
                      <TableCell align="left">{row.delta_t}</TableCell>
                      <TableCell align="left">{row.overflow}</TableCell>
                      <TableCell align="left">{row.return_temperature}</TableCell>
                      <TableCell align="left">{row.flow_temperature}</TableCell>
                      <TableCell>
                        {row.metering_point_status === "" ? (
                          <FormattedMessage id="alarm.none" />
                        ) : (
                          <FormattedMessage id={"metering_points.status_" + row.metering_point_status} />
                        )}
                      </TableCell>
                      <TableCell align="left">{row.newest_note}</TableCell>
                    </TableRow>
                  );
                })}
              {10 - (rowsPerPage - emptyRows) > 0 && (
                <TableRow style={{ height: 53 * (10 - (rowsPerPage - emptyRows)) }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={6}>
                  <div align="center">
                    <Typography>
                      <FormattedMessage id="alarm.loading" />
                    </Typography>
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
        <TablePagination
          component="div"
          count={filteredAlarms.length}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage={intl.formatMessage({ id: "alarm.rows_per_page" })}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} ${intl.formatMessage({ id: "alarm.of" })} ${count}`
          }
          page={page}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

AlarmsTable.propTypes = {
  alarms: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  tags: PropTypes.array.isRequired,
  period: PropTypes.string.isRequired,
};

export default withStyles(styles)(injectIntl(AlarmsTable));
