import { useEffect, useState } from "react";
import useMeterReadings from "./useMeterReadings";
import moment from "moment";
import { useIntl } from "react-intl";
import localforage from "localforage";

function useStatus(day) {
  const [nDays, setnDays] = useState(parseInt(window.sessionStorage.getItem("importNDays")) || 1);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const intl = useIntl();

  const [dayStatus, setDayStatus] = useState({
    errorList: [],
    missingMeasuresList: [],
  });
  const [days, setDays] = useState(getDayArray(nDays));
  const { getStatus } = useMeterReadings();

  function getDayArray(n) {
    return [...Array(n).keys()].map((n) => moment(day).subtract(n, "days").format("YYYY-MM-DD"));
  }

  async function calcStatus() {
    setIsLoading(true);

    try {
      let status = await localforage.getItem("importStatus");
      const firstDayIsCached = status && Object.keys(status)[0] === day;
      const hasSameLength = status && days.length === Object.keys(status).length;
      const hasSameDays = status && days.every((d) => Object.keys(status).indexOf(d) !== -1);
      if (!status || !firstDayIsCached || !hasSameLength || !hasSameDays) {
        status = await getStatus(nDays);
        await localforage.setItem("importStatus", status);
      }

      Object.keys(status).forEach((day) => {
        status[day].error_list.forEach((err) => {
          err.error_code = intl.formatMessage({
            id: "status." + err.error_code,
          });
        });
      });
      setDayStatus(status);
    } catch (err) {
      console.log(err);
      setErrorMessage(intl.formatMessage({ id: "status.failed_to_fetch_status" }));
    }
    setIsLoading(false);
  }

  useEffect(() => {
    calcStatus();
  }, [days]);

  useEffect(() => {
    setDays(getDayArray(nDays));
  }, [nDays]);

  const wrap = (str, size = 3, delimiter = "<br>") => {
    const words = str.split(" ");
    return Array.from({ length: Math.ceil(words.length / size) }, (v, i) =>
      words.slice(i * size, i * size + size).join(" ")
    ).join(delimiter);
  };

  const formatStatusLabels = ({ intl, dayStatus, yesterday }) => {
    return [
      intl.formatMessage({ id: "status.no_measures" }),
      intl.formatMessage({ id: "status.no_error" }),
      ...Object.keys(dayStatus[yesterday].categories).map((cat) => {
        return wrap(intl.formatMessage({ id: "status." + cat }));
      }),
    ];
  };

  const setNDays = (n) => {
    setnDays(n);
    window.sessionStorage.setItem("importNDays", n);
  };

  return {
    isLoading,
    dayStatus,
    prevDays: days,
    setnDays: setNDays,
    nDays,
    formatStatusLabels,
    errorMessage,
  };
}

export default useStatus;
