import React, {useState, useContext} from "react";
import { injectIntl } from "react-intl";
import styles from "../styles";
import { withStyles, makeStyles, Dialog, Button, Typography, IconButton, Grid } from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import CloseIcon from '@material-ui/icons/Close';
import axios from "axios";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import AppContext from "../AppContext";

const useStyles = makeStyles(styles);


const dialogTitleStyles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
});
  
const DialogTitle = withStyles(dialogTitleStyles)((props) => {
  const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
  );
});


const UploadDialog = (props) => {
  const classes = useStyles();
  const [selectedFile, setSelectedFile] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [errors, setErrors] = useState([]);
  const [message, setMessage] = useState("");
  
  const context = useContext(AppContext);

  const selectFile = (event) => {
      setSelectedFile(event.target.files[0])

  }

  const upload = (event) => {

    let url = context.config.meteringPointsAPI+"/protocol";
    let formData = new FormData();
    formData.append("file", selectedFile);

    context.keycloak
      .updateToken(5)
      .success(function() {
        var config = {
          method: "post",
          url: url,
          headers: {
            Authorization: "Bearer " + context.keycloak.token
          },
          data: formData,
          responseType: "json"
        };
        axios(config)
          .then((response) => {

            var data = response.data;
            var isValid = data["isValid"]        
            if (isValid){
                setHasError(false)
                var msg = props.intl.formatMessage({ id: "metering_point.protocol_upload_success" })
                setMessage(msg)
                setErrors([])
                props.fetchAllMeteringPoints()
            }else{
                setHasError(true)
                var msg = props.intl.formatMessage({ id: "metering_point.protocol_upload_contains_error" })
                setMessage(msg +  ": " + data["error_overview"])
                var errors = []
                if ("errors" in data) {
                  errors = data["errors"]
                }
                setErrors(errors)
            }

          })
          .catch(error => {
            setMessage("Error "+ error.message)
          });
      })
      .error(function() {
        context.keycloak.login();
      });
  
  }

  const cleanStateAndClose = () => {
    setHasError(false)
    setErrors([])
    setMessage("")
    setSelectedFile(null)
    props.toggleProtocolUploadDialog()
    
  }

  return(     
    <Dialog fullWidth={true} maxWidth="md"
        open={props.showProtocolUploadDialog} onClose={cleanStateAndClose}>
        <DialogTitle id="customized-dialog-title" onClose={cleanStateAndClose}>
            {props.intl.formatMessage({ id: "metering_point.protocol_upload" })}
        </DialogTitle>
        <MuiDialogContent>
            <Grid container>
                    <Grid item xs={2}>
                        <label htmlFor="btn-upload">
                            <input
                                id="btn-upload"
                                name="btn-upload"
                                style={{ display: 'none' }}
                                type="file"
                                onChange={selectFile} />
                            <Button
                                className="btn-choose"
                                variant="outlined"
                                component="span" >
                                {props.intl.formatMessage({ id: "metering_point.protocol_upload_choose_file" })}
                            </Button>
                        </label>
                        <p>
                            {selectedFile!=null ? selectedFile.name : null}
                        </p>
                    </Grid>
                    <Grid item xs={2}>    
                        <Button
                            className="btn-upload"
                            color="primary"
                            variant="contained"
                            component="span"
                            disabled={selectedFile==null}
                            onClick={upload}>
                            {props.intl.formatMessage({ id: "metering_point.protocol_upload_file" })}
                        </Button>
                    </Grid>
                    <Grid item xs={12}>    
                        <Typography variant="subtitle2">
                            {message}
                        </Typography>
                        <TableContainer component={Paper} display = {hasError ? true:false}>
                          <Table className={classes.table} aria-label="simple table">
                            
                            <TableBody>
                              {errors.map((x) => (
                                <TableRow >
                                   <TableCell component="th" scope="row">{x.row}</TableCell>
                                   <TableCell align="right"><div className="error"> {JSON.stringify(x.detail) } </div></TableCell>
                                  
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                    </Grid>
            </Grid>    
        </MuiDialogContent>
        
    </Dialog>
    );
};


export default withStyles(styles)(injectIntl(UploadDialog));