import React from "react";
import axios from "axios";
import { FormattedMessage, injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import PropTypes from "prop-types";
import styles from "../styles";

import { withStyles, MenuItem, TextField, Grid, Paper, Badge, Tooltip, IconButton, Dialog, Button, FormControlLabel, Switch } from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";

import NoteIcon from "@material-ui/icons/Note";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import CalculatorIcon from "./CalculatorIcon";
import UndoIcon from "@material-ui/icons/Undo";
import DoneIcon from "@material-ui/icons/Done";
import VisibilityIcon from "@material-ui/icons/Visibility";
import AddBoxIcon from "@material-ui/icons/AddBox";

import NotesIcon from'@material-ui/icons/Notes';
import LabelIcon from'@material-ui/icons/Label';

import MeteringPointTags from "./MeteringPointTags";
import RefPeriodPreview from "./RefPeriodPreview";
import CreateProtocolModal from "./CreateProtocolModal";
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import ErrorMessage from "./ErrorMessage";

export const statuses = ["ok", "under_surveillance", "under_investigation", "investigated", "forget", ""];

/**
 * This class handle metering point information
 */
class MeteringPointInformation extends React.Component {
  constructor(props) {
    super(props);

    var defaultRefFrom = new Date();
    defaultRefFrom.setUTCHours(0, 0, 0, 0);
    defaultRefFrom.setUTCMonth(0)
    defaultRefFrom.setUTCDate(1)
    defaultRefFrom.setUTCFullYear(defaultRefFrom.getUTCFullYear() - 1)

    var defaultRefTo = new Date();
    defaultRefTo.setUTCHours(0, 0, 0, 0);
    defaultRefTo.setUTCMonth(11)
    defaultRefTo.setUTCDate(31)
    defaultRefTo.setUTCFullYear(defaultRefTo.getUTCFullYear() - 1)

    this.state = {
      selectedOption: props.meteringpoint.status ? props.meteringpoint.status[0] : "",
      showAllInfo: false,
      referencePeriodStart:
        !!this.props.meteringpoint.reference_from
          ? new Date(this.props.meteringpoint.reference_from)
          .toLocaleDateString("sv-SE") : "",
      referencePeriodEnd:
        !!this.props.meteringpoint.reference_to
          ? new Date(this.props.meteringpoint.reference_to)
          .toLocaleDateString("sv-SE") : "",
      staticRefPeriod: !!this.props.meteringpoint.reference_from && !!this.props.meteringpoint.reference_to, 
      dialogRefPeriodVisable: false,
      dialogAddMeterChangeVisible: false,
      addMeterChangeDate: Date.now(),
    };

    this.state.referencePeriodStartText = this.state.referencePeriodStart || defaultRefFrom.toLocaleDateString("sv-SE");
    this.state.referencePeriodEndText = this.state.referencePeriodEnd || defaultRefTo.toLocaleDateString("sv-SE");
    this.state.errorOpen = false;
    this.state.revalidating = false;
  }

  handleChange(event) {    
    this.setState({value: event.target.value});  
  }

  /**
   *
   * @param {int} value The number to be rounded
   * @param {int} number_of_decimals How many decimals to round the number to
   */
  roundValue = (value, number_of_decimals) => {
    try {
      return +value.toFixed(number_of_decimals);
    } catch (error) {
      return null;
    }
  };

  handleAddMeterChange = () => {
    var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    let addMeterChangeType = document.getElementById("meter_change_type_add").value;
    let addMeterChangeDate = new Date(new Date(this.state.addMeterChangeDate).getTime() - tzoffset);
    addMeterChangeDate = addMeterChangeDate.toISOString().substring(0,10);
    let addMeterChangeSerialNumber = document.getElementById("meter_change_serial_number_add").value;
    let addMeterChangeComment = document.getElementById("meter_change_comment_add").value;

    var thiz = this;
    if (!addMeterChangeType || !addMeterChangeDate || !addMeterChangeSerialNumber || !addMeterChangeComment) {
      thiz.setState({
        errorOpen: true,
        errorMessage: thiz.props.intl.formatMessage({
          id: "metering_point_information.add_meter_change_missing_info"
        })
      });
      return;
    }

    //Make API call TODO change parameters
    this.props.context.keycloak
      .updateToken(5)
      .success(function () {
        var config = {
          method: "post",
          url:
            thiz.props.context.config.meteringPointsAPI +
            "/" +
            thiz.props.meteringpoint.metering_point_id +
            "/meter_change",
          headers: {
            Authorization: "Bearer " + thiz.props.context.keycloak.token,
          },
          // params: {
          //   metering_point_id: thiz.props.meteringpoint.metering_point_id,
          // },
          data: [{
            type: addMeterChangeType,
            datestamp: addMeterChangeDate,
            serial_number: addMeterChangeSerialNumber,
            comment: addMeterChangeComment
          }],
        };
        axios(config)
          .then((response) => {
            if (response.status === 200) {
              thiz.props.handleReloadMeteringPoint();
            }
          })
          .catch((error) => {
            console.log(error);
            // if (error.response.status === 500) {} //TODO Different message depending on code?
            thiz.setState({
              errorOpen: true,
              errorMessage: thiz.props.intl.formatMessage({
                id: "metering_point_information.error_message_add_meter_change",
              }), // + " ("  + response.statusText + ")" //TODO Improve error message from microservice?
            });
          });
      })
      .error(function () {
        thiz.props.context.keycloak.login();
      });
  }

  handleErrorClose = () => {
    this.setState({ errorOpen: false });
  };

  handleRefPeriodPreview = () => {
    this.setState({ dialogRefPeriodVisable: true });
  };

  handleRefPeriodClose = (reload=true) => {
    this.setState({ dialogRefPeriodVisable: false });
    if (reload === true) {
      this.props.handleReloadMeteringPoint();
    }
  };
  
  putReferencePeriod = async (from, to) => {
    var config = {
      method: "put",
      url:
        this.props.context.config.meteringPointsAPI +
        "/" +
        this.props.meteringpoint.metering_point_id +
        "/reference_period",
      headers: {
        Authorization: "Bearer " + this.props.context.keycloak.token,
      },
      data: {
        from,
        to,
      },
    };
    return await axios(config);  
  }

  handleUpdateRefPeriodAsync = async (from, to) => {
    const referencePeriodStart = this.state.referencePeriodStartText.split("T")[0];
    const referencePeriodEnd = this.state.referencePeriodEndText.split("T")[0];
    try {
      await this.props.context.keycloak.updateToken(5);
      this.setState({revalidating: true});
      try {
        await this.putReferencePeriod(referencePeriodStart, referencePeriodEnd);
        await this.revalidateData("kasper")
        this.props.handleReloadMeteringPoint();
      } catch(error) {
        console.log(error)
        this.setState({
          revalidating: false, 
          errorOpen: true,
          errorMessage: thiz.props.intl.formatMessage({
            id: "metering_point_information.error_message_update_reference_period",
          })
        });
      } finally {
        this.setState({revalidating: false})
      }
    } catch(err) {
      this.props.context.keycloak.login();
    }
  }


  handleRevertRefPeriod = () => {
    this.setState({
      referencePeriodStartText:
        !!this.props.meteringpoint.reference_from
          ? new Date(this.state.referencePeriodStart).toLocaleDateString("sv-SE")
          : " ",
      referencePeriodEndText:
      !!this.props.meteringpoint.reference_to
          ? new Date(this.state.referencePeriodEnd).toLocaleDateString("sv-SE")
          : " "
    }, () => this.props.setPreviewRefPeriod(null));
  };

  /**
   * Update the text denoting the start of the reference period
   * @param {*} event search param from date input label
   */
  handleRefFromDateInput = (event) => {
    if (event && event.isValid()) {
      this.setState({
        referencePeriodStartText: new Date(
          Date.UTC(event._d.getFullYear(), event._d.getMonth(), event._d.getDate())
        ).toISOString(),
      });
    }
  };

  /**
   * Update the text denoting the end of the reference period
   * @param {*} event search param to date input label
   */
  handleRefToDateInput = (event) => {
    if (event && event.isValid()) {
      this.setState({
        referencePeriodEndText: new Date(
          Date.UTC(event._d.getFullYear(), event._d.getMonth(), event._d.getDate())
        ).toISOString(),
      });
    }
  };

  navigateToRedebit = () => {
    this.props.history.push({
      pathname: "/diffcalculation/" + this.props.meteringpoint.metering_point_id,
      state: {
        referenceFrom: this.state.referencePeriodStart,
        referenceTo: this.state.referencePeriodEnd,
        appliedFrom: this.state.referencePeriodStart,
        appliedTo: this.state.referencePeriodEnd,
      },
    });
  };

  toggleShowAllInfo = () => {
    this.setState({
      showAllInfo: !this.state.showAllInfo,
    });
  };

  hasPrivileges = (role) => {
    if (
      (role === "admin" && this.props.context.keycloak.role === "admin") ||
      (role === "analyst" &&
        (this.props.context.keycloak.role === "analyst" || this.props.context.keycloak.role === "admin"))
    ) {
      return true;
    } else {
      return false;
    }
  };

  updateStatus = (event) => {
    var thiz = this;
    var newStatus = event.target.value;
    this.props.context.keycloak
      .updateToken(5)
      .success(function () {
        var config = {
          method: "put",
          url:
            thiz.props.context.config.meteringPointsAPI + "/" + thiz.props.meteringpoint.metering_point_id + "/status",
          headers: {
            Authorization: "Bearer " + thiz.props.context.keycloak.token,
          },
          data: [newStatus],
        };
        axios(config)
          .then((response) => {
            thiz.setState({ selectedOption: newStatus });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .error(function () {
        thiz.props.context.keycloak.login();
      });
  };

  removeRefPeriod = async () => {
    var thiz = this;
    try {
      this.setState({revalidating: true})
      await this.props.context.keycloak.updateToken(5)
      const config = {
        method: "delete",
        url:
          thiz.props.context.config.meteringPointsAPI + "/" + thiz.props.meteringpoint.metering_point_id + "/reference_period",
        headers: {
          Authorization: "Bearer " + thiz.props.context.keycloak.token,
        }
      };
      await axios(config)
      await this.revalidateData("arnold");
      thiz.props.handleReloadMeteringPoint();
    } catch(err) {
      thiz.props.context.keycloak.login();
    }
    this.setState({revalidating: false})
  }

  revalidateData = async (method) => {
    var thiz = this;
    const url = this.props.context.config.regressionAPI + (method === "arnold" ? "/validate_arnold" : "/validate");
    
    var dataIncludeFrom = new Date();
    dataIncludeFrom.setUTCHours(0, 0, 0, 0);
    var dataIncludeTo = new Date();
    dataIncludeTo.setUTCHours(0, 0, 0, 0);
    dataIncludeFrom.setUTCFullYear(dataIncludeFrom.getUTCFullYear() - 3);

    await this.props.context.keycloak.updateToken(5)

    let params = {
      metering_point_id: thiz.props.meteringpoint.metering_point_id,
      include_from: dataIncludeFrom.toISOString(),
      include_to: dataIncludeTo.toISOString(),
      weather_station_id: thiz.props.meteringpoint.weather_station_id,
      weather_station_agency: thiz.props.meteringpoint.weather_station_agency,
    }
    if (method === "arnold") {
      params = {...params, do_crossval: true}
    } else {
      params = {
        ...params, 
        ref_from: this.state.referencePeriodStartText, 
        ref_to: this.state.referencePeriodEndText}
    }

    const config = {
      method: "put",
      params,
      url:url,
      headers: {
        Authorization: "Bearer " + thiz.props.context.keycloak.token
      },
      responseType: "json"
    };
    await axios(config)
  }  

  /**
   * Call this function to create and return a TextField component
   * @param {*} id The component id
   * @param {*} value The value to be displayed in the TextField
   * @param {*} readOnly Whether the TextField should be read-only
   */
  meteringPointInformationTextField(id, value, readOnly=true, onChangeFunc=null) {
    return (
      <TextField
        id={id}
        className={this.props.classes.TextField}
        label={this.props.intl.formatMessage({
          id: "metering_points." + id,
        })}
        value={value ? value : ""}
        inputProps={{
          readOnly: readOnly,
          style: {
            textOverflow: 'ellipsis'
          }
        }}
        InputLabelProps={{
          shrink: true,
        }}
        margin="normal"
        onChange={onChangeFunc}
      />
    );
  }

  meteringPointInformationTextFieldUncontrolled(id, value=null) {
    return (
      <TextField
        id={id}
        className={this.props.classes.TextField}
        label={this.props.intl.formatMessage({
          id: "metering_points." + id,
        })}
        defaultValue={value}
        InputLabelProps={{
          shrink: true,
        }}
        margin="normal"
      />
    );
  }

  /**
   * this method render the html layout
   */
  render() {
    const meteringpoint = this.props.meteringpoint;
    const { selectedOption, showAllInfo } = this.state;
    const { classes } = this.props;

    // Define all text information fields here as variables
    // to make the rest of the code more readable

    const meteringPointID = this.meteringPointInformationTextField("metering_point", meteringpoint.metering_point_id);
    const meterID = this.meteringPointInformationTextField("meter_id", meteringpoint.meter_id);
    const meteringPointType = this.meteringPointInformationTextField("type", meteringpoint.type);
    const meteringPointManufacturer = this.meteringPointInformationTextField(
      "manufacturer",
      meteringpoint.manufacturer
    );

    const meteringPointModel = this.meteringPointInformationTextField("model", meteringpoint.model);

    const meteringPointAnnualConsumptionEnergy = this.meteringPointInformationTextField(
      "annual_consumption_energy",
      "annual_consumption" in meteringpoint ? this.roundValue(meteringpoint.annual_consumption.energy, 0) : 0
    );

    const meteringPointAnnualConsumptionFlow = this.meteringPointInformationTextField(
      "annual_consumption_flow",
      "annual_consumption" in meteringpoint ? this.roundValue(meteringpoint.annual_consumption.flow, 0) : 0
    );

    const meteringPointStatus = (
      <TextField
        className={classes.TextField}
        id="status"
        select
        InputProps={{
          readOnly: !this.hasPrivileges("analyst"),
        }}
        label={this.props.intl.formatMessage({
          id: "metering_points.status",
        })}
        InputLabelProps={{
          shrink: true,
        }}
        value={selectedOption}
        onChange={this.updateStatus}
        margin="normal"
      >
        {statuses.map((status) => (
          <MenuItem key={status} value={status}>
            <FormattedMessage id={"metering_points.status_" + status} />
          </MenuItem>
        ))}
      </TextField>
    );
    let latest_metering_point_change;

    if (meteringpoint.meter_changes && meteringpoint.meter_changes.length > 0) {
      latest_metering_point_change = meteringpoint.meter_changes.sort((a, b) => {
        return new Date(b.datestamp).getTime() - new Date(a.datestamp).getTime();
      })[0];
    }

    const meteringPointMeterChangeDate = this.meteringPointInformationTextField(
      "meter_change_date",
      latest_metering_point_change ? new Date(latest_metering_point_change.datestamp).toLocaleDateString("sv-SE") : null,
    );
    const meteringPointMeterChangeType = this.meteringPointInformationTextField(
      "meter_change_type",
      latest_metering_point_change ? latest_metering_point_change.type : null,
    );
    const meteringPointMeterChangeSerialNumber = this.meteringPointInformationTextField(
      "meter_change_serial_number",
      latest_metering_point_change ? latest_metering_point_change.serial_number : null,
    );
    const meteringPointMeterChangeComment = this.meteringPointInformationTextField(
      "meter_change_comment",
      latest_metering_point_change ? latest_metering_point_change.comment : null,
    );

    const meteringPointMeterChangeDateAdd = (
      <KeyboardDatePicker
        className={classes.DatePicker}
        id="meter_change_date_add"
        allowKeyboardControl
        autoOk
        disableFuture
        invalidDateMessage={this.props.intl.formatMessage({
          id: "invalid_date_message",
        })}
        minDateMessage={this.props.intl.formatMessage({
          id: "min_date_message",
        })}
        maxDateMessage={this.props.intl.formatMessage({
          id: "max_date_message",
        })}
        label={this.props.intl.formatMessage({
          id: "metering_points.meter_change_date_add",
        })}
        InputLabelProps={{ shrink: true }}
        format="YYYY-MM-DD"
        onChange={(value) => this.setState({addMeterChangeDate: value})}
        value={this.state.addMeterChangeDate}
      />
    );
    const meteringPointMeterChangeTypeAdd = this.meteringPointInformationTextFieldUncontrolled(
      "meter_change_type_add"
    );
    const meteringPointMeterChangeSerialNumberAdd = this.meteringPointInformationTextFieldUncontrolled(
      "meter_change_serial_number_add"
    );
    const meteringPointMeterChangeCommentAdd = this.meteringPointInformationTextFieldUncontrolled(
      "meter_change_comment_add"
    );
    const meteringPointMeterChangeAddButton = (
      <Tooltip
        placement="top"
        title={this.props.intl.formatMessage({
          id: "metering_points.meter_change.tooltip"
        })}
      >
        <IconButton 
          onClick={() => {this.setState({dialogAddMeterChangeVisible: true})}}
          style={{marginLeft: "-16px"}}
        >
          <AddBoxIcon />
        </IconButton>
      </Tooltip>
    );
    
    const addMeterChangeModal = (
      <Dialog fullWidth={true}
        onClose={() => {this.setState({dialogAddMeterChangeVisible: false})}}
        open={this.state.dialogAddMeterChangeVisible}
      >
        <MuiDialogTitle>{this.props.intl.formatMessage({ id: "metering_points.meter_change.add" })}</MuiDialogTitle>
        <MuiDialogContent>
        <Grid container>
          <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              {meteringPointMeterChangeDateAdd}
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6}>{meteringPointMeterChangeTypeAdd}</Grid>
          <Grid item xs={6}>{meteringPointMeterChangeSerialNumberAdd}</Grid>
          <Grid item xs={6}>{meteringPointMeterChangeCommentAdd}</Grid>
        </Grid>
        </MuiDialogContent>
        <MuiDialogActions>
          <Button variant="contained" onClick={this.handleAddMeterChange} color="primary">
            {this.props.intl.formatMessage({ id: "metering_points.meter_change.add" })}
          </Button>
          <Button variant="contained" onClick={() => {this.setState({dialogAddMeterChangeVisible: false})}} color="secondary">
            {this.props.intl.formatMessage({ id: "metering_points.meter_change.close" })}
          </Button>
        </MuiDialogActions>
      </Dialog>
    );

    const meteringPointRefPeriodStart = (
      <KeyboardDatePicker
        className={classes.DatePicker}
        allowKeyboardControl
        autoOk
        disableFuture
        invalidDateMessage={this.props.intl.formatMessage({
          id: "invalid_date_message",
        })}
        minDateMessage={this.props.intl.formatMessage({
          id: "min_date_message",
        })}
        maxDateMessage={this.props.intl.formatMessage({
          id: "max_date_message",
        })}
        label={this.props.intl.formatMessage({
          id: "metering_points.ref_period_from",
        })}
        InputLabelProps={{ shrink: true }}
        format="YYYY-MM-DD"
        onChange={this.handleRefFromDateInput}
        value={this.state.referencePeriodStartText}
      />
    );

    const meteringPointRefPeriodEnd = (
      <KeyboardDatePicker
        className={classes.DatePicker}
        allowKeyboardControl
        autoOk
        disableFuture
        invalidDateMessage={this.props.intl.formatMessage({
          id: "invalid_date_message",
        })}
        minDateMessage={this.props.intl.formatMessage({
          id: "min_date_message",
        })}
        maxDateMessage={this.props.intl.formatMessage({
          id: "max_date_message",
        })}
        label={this.props.intl.formatMessage({
          id: "metering_points.ref_period_to",
        })}
        InputLabelProps={{ shrink: true }}
        format="YYYY-MM-DD"
        onChange={this.handleRefToDateInput}
        value={this.state.referencePeriodEndText}
      />
    );

    const meteringPointRefPeriodPreviewButton = (
      <div style={{marginLeft:"-16px"}} >
        <Tooltip title={this.props.intl.formatMessage({ id: "metering_point.ref_preview.show_preview" })}>
          <IconButton 
            onClick={() => this.props.setPreviewRefPeriod(
              this.state.referencePeriodStartText.split("T")[0],
              this.state.referencePeriodEndText.split("T")[0]
            )}
          >
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
      </div>
    );

    const meteringPointRefPeriodStatsButton = (
      <div style={{marginLeft:"-16px"}} >
        <Tooltip title={this.props.intl.formatMessage({ id: "metering_point.ref_preview.stats" })}>
          <IconButton onClick={this.handleRefPeriodPreview}>
            <FormatListNumberedIcon />
          </IconButton>
        </Tooltip>
        <RefPeriodPreview
          open={this.state.dialogRefPeriodVisable}
          closeHandle={this.handleRefPeriodClose}
          refFrom={this.state.referencePeriodStartText}
          refTo={this.state.referencePeriodEndText}
          meteringPointId={this.props.meteringpoint.metering_point_id}
        />
      </div>
    );

    const changeRefPeriodButtonsDisabled =
      !this.hasPrivileges("analyst") ||
      (this.state.referencePeriodStartText === this.state.referencePeriodStart &&
        this.state.referencePeriodEndText === this.state.referencePeriodEnd);

    const meteringPointRefPeriodUndoButton = (
      <Tooltip
        placement="top"
        title={this.props.intl.formatMessage({id: "metering_point.ref_preview.cancel"})}
      >
        <span>
          <IconButton color="secondary" onClick={this.handleRevertRefPeriod} disabled={changeRefPeriodButtonsDisabled}>
            <UndoIcon />
          </IconButton>
        </span>
      </Tooltip>
    );

    const meteringPointRefPeriodUpdateButton = (
      <Tooltip
        placement="bottom"
        title={this.props.intl.formatMessage({id: "metering_point.ref_preview.confirm"})}
      >
        <span>
          <IconButton color="primary" onClick={this.handleUpdateRefPeriodAsync} disabled={changeRefPeriodButtonsDisabled}>
            <DoneIcon />
          </IconButton>
        </span>
      </Tooltip>
    );

    const meteringPointRefPeriodButtons = (
      <Grid container spacing={2}>
        <Grid item>
          <div style={{ height: "60px", marginLeft: "-20px", marginRight: "-16px", marginTop: "-16px" }}>
            <div style={{ height: "50%" }}>{meteringPointRefPeriodUndoButton}</div>
            <div style={{ height: "50%" }}>{meteringPointRefPeriodUpdateButton}</div>
          </div>
        </Grid>
        <Grid item>
          <div style={{ height: "60px", marginLeft: "0px", marginRight: "-16px", marginTop: "-16px" }}>
            <div style={{ height: "50%" }}>{meteringPointRefPeriodPreviewButton}</div>
            <div style={{ height: "50%" }}>{meteringPointRefPeriodStatsButton}</div>
          </div>
        </Grid>
      </Grid>
    );

    const meteringPointLatitude = this.meteringPointInformationTextField(
      "latitude",
      meteringpoint.location
        ? meteringpoint.location.position
          ? meteringpoint.location.position.latitude
          : null
        : null
    );
    const meteringPointLongitude = this.meteringPointInformationTextField(
      "longitude",
      meteringpoint.location
        ? meteringpoint.location.position
          ? meteringpoint.location.position.longitude
          : null
        : null
    );
    const meteringPointAltitude = this.meteringPointInformationTextField(
      "altitude",
      meteringpoint.location
        ? meteringpoint.location.position
          ? meteringpoint.location.position.altitude
          : null
        : null
    );
    const meteringPointReadingFrequency = this.meteringPointInformationTextField(
      "reading_frequency",
      meteringpoint.reading_frequency
    );
    const meteringPointWeatherStationID = this.meteringPointInformationTextField(
      "weather_station_id",
      meteringpoint.weather_station_id
    );
    const meteringPointWeatherStationAgency = this.meteringPointInformationTextField(
      "weather_station_agency",
      meteringpoint.weather_station_agency
    );
    const meteringPointStreetAddressText = (meteringpoint.location
      ? meteringpoint.location.address
        ? meteringpoint.location.address.street_address
        : null
      : null);
    const meteringPointStreetAddress = (meteringPointStreetAddressText
        && meteringPointStreetAddressText.length > 18) ?
    <Tooltip placement="top" title={<h2>{meteringPointStreetAddressText}</h2>}>
      {this.meteringPointInformationTextField(
        "street",
        meteringPointStreetAddressText
      )}
    </Tooltip> :
    this.meteringPointInformationTextField(
      "street",
      meteringPointStreetAddressText
    );
    const meteringPointPostalCode = this.meteringPointInformationTextField(
      "postal_code",
      meteringpoint.location
        ? meteringpoint.location.address
          ? meteringpoint.location.address.postal_code
          : null
        : null
    );
    const meteringPointCity = this.meteringPointInformationTextField(
      "city",
      meteringpoint.location ? (meteringpoint.location.address ? meteringpoint.location.address.city : null) : null
    );

    const expandButton = (
      <IconButton onClick={this.toggleShowAllInfo}>{showAllInfo ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
    );

    const redebitButton = (
      <Tooltip
        title={this.props.intl.formatMessage({
          id: "diffcalculation",
        })}
      >
        <IconButton onClick={this.navigateToRedebit}>
          <CalculatorIcon />
        </IconButton>
      </Tooltip>
    );

    const toggleShowNotesButton = (
      <Tooltip
        title={this.props.intl.formatMessage({
          id: "metering_points.show_notes",
        })}
      >
        <IconButton onClick={this.props.handleToggleShowNotes}>
          <Badge badgeContent={meteringpoint.notes.length} color="secondary">
            <NoteIcon />
          </Badge>
        </IconButton>
      </Tooltip>
    );

    const toggleShowProtocolsButton = (
      <Tooltip
        title= "Visa protokoll"
      >
        <IconButton onClick={this.props.handleToggleShowProtocols}>
          <Badge badgeContent={this.props.getProtocolsLength()} color="secondary">
            <NotesIcon />
          </Badge>
        </IconButton>
      </Tooltip>
    );
    
    const toggleShowLabelsButton = (
      <Tooltip
        title={this.props.intl.formatMessage({
          id: "metering_points.show_labels",
        })}
      >
        <IconButton onClick={this.props.handleToggleShowLabels}>
          <Badge badgeContent={this.props.getLabelsLength()} color="secondary">
            <LabelIcon />
          </Badge>
        </IconButton>
      </Tooltip>
    );

    const meteringPointTags = (
      <MeteringPointTags
        context={this.props.context}
        meteringPointId={this.props.meteringpoint.metering_point_id}
        compact={!showAllInfo}
      />
    )

    const toggleRefPeriod = (
      <FormControlLabel
        style={{marginLeft:"-3px", marginTop:"4px"}}
        label={
          <div className={classes.SwitchLabel}>
            {this.props.intl.formatMessage({id: "metering_points.static_ref_period"})}
          </div>
        }
        labelPlacement="top"
        control={
            <Switch
              checked={this.state.staticRefPeriod}
              onChange={() => {
                if (!this.state.staticRefPeriod) {
                  this.setState({staticRefPeriod: true})
                } else {
                  if (window.confirm(this.props.intl.formatMessage({id: "metering_points.remove_ref_period"}))) {
                    this.removeRefPeriod()
                  }
                }
              }}
              color="primary"
            />
        }
      />
    )

    const RefPeriod = () => (
      this.state.staticRefPeriod &&
        <>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Grid item>{meteringPointRefPeriodStart}</Grid>
            <Grid item>{meteringPointRefPeriodEnd}</Grid>
          </MuiPickersUtilsProvider>
          <Grid item>{meteringPointRefPeriodButtons}</Grid> 
        </>
    )

    return (
      <React.Fragment>
        {
          this.state.revalidating &&
          <div className={classes.Revalidating}>{this.props.intl.formatMessage({id: "metering_points.revalidating"})}</div>
        }
        <ErrorMessage open={this.state.errorOpen} onClose={this.handleErrorClose} message={this.state.errorMessage} />
        <Paper className={classes.Paper}>
          {!showAllInfo ? (
            <Grid container spacing={2}>
              <Grid item>{meteringPointID}</Grid>
              <Grid item>{meteringPointStreetAddress}</Grid>
              <Grid item>{meteringPointStatus}</Grid>
              <Grid item>{meteringPointAnnualConsumptionEnergy}</Grid>
              <Grid item>{meteringPointAnnualConsumptionFlow}</Grid>
              <Grid item>{toggleRefPeriod}</Grid>
              <RefPeriod />
              <Grid item xs={2}>{meteringPointTags}</Grid>
              <Grid item className={classes.FlexGrow}/>{/* Empty grid item used to align next item to the right */}
              <Grid item>
                {toggleShowLabelsButton}
                {toggleShowProtocolsButton}
                {redebitButton}
                {toggleShowNotesButton}
                {expandButton}
              </Grid>
            </Grid>
          ) : (
            <React.Fragment>
              <Grid container spacing={2}>
                <Grid item sm={3}>
                  <Grid container spacing={2}>
                    <Grid item>{meteringPointID}</Grid>
                    <Grid item>{meterID}</Grid>
                    <Grid item>{meteringPointType}</Grid>
                    <Grid item>{meteringPointManufacturer}</Grid>
                    <Grid item>{meteringPointModel}</Grid>
                    <Grid item>{meteringPointStatus}</Grid>
                    <Grid item>{meteringPointMeterChangeDate}</Grid>
                    <Grid item>{meteringPointMeterChangeType}</Grid>
                    <Grid item>{meteringPointMeterChangeSerialNumber}</Grid>
                    <Grid item>{meteringPointMeterChangeComment}</Grid>
                    <Grid item>{meteringPointMeterChangeAddButton}</Grid>
                    {addMeterChangeModal}
                  </Grid>
                </Grid>

                <Grid item sm={3}>
                  <Grid container spacing={2}>
                    {/* <MuiPickersUtilsProvider utils={MomentUtils}>
                      <Grid item>{meteringPointRefPeriodStart}</Grid>
                      <Grid item>{meteringPointRefPeriodEnd}</Grid>
                    </MuiPickersUtilsProvider>
                    <Grid item>{meteringPointRefPeriodButtons}</Grid> */}
                    <Grid item>{meteringPointAnnualConsumptionEnergy}</Grid>
                    <Grid item>{meteringPointAnnualConsumptionFlow}</Grid>
                    <Grid item>{meteringPointWeatherStationID}</Grid>
                    <Grid item>{meteringPointWeatherStationAgency}</Grid>
                    <Grid item>{meteringPointReadingFrequency}</Grid>
                  </Grid>
                </Grid>

                <Grid item sm={3}>
                  <Grid container spacing={2}>
                    <Grid item>{meteringPointStreetAddress}</Grid>
                    <Grid item>{meteringPointPostalCode}</Grid>
                    <Grid item>{meteringPointCity}</Grid>
                    <Grid item>{meteringPointLatitude}</Grid>
                    <Grid item>{meteringPointLongitude}</Grid>
                    <Grid item>{meteringPointAltitude}</Grid>
                  </Grid>
                </Grid>

                <Grid item sm={3}>
                  {meteringPointTags}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item sm={4} />
                <Grid item sm={4} className={classes.AlignCenter}>
                  {expandButton}
                </Grid>
                <Grid item sm={4} className={classes.AlignRight}>
                  {toggleShowLabelsButton}
                  {toggleShowProtocolsButton}
                  {redebitButton}
                  {toggleShowNotesButton}
                </Grid>
              </Grid>
            </React.Fragment>
          )}
        </Paper>
      </React.Fragment>
    );
  }
}

MeteringPointInformation.propTypes = {
  meteringpoint: PropTypes.object.isRequired,
  handleToggleShowNotes: PropTypes.func.isRequired,
  handleReloadMeteringPoint: PropTypes.func.isRequired,
  context: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(injectIntl(MeteringPointInformation)));
